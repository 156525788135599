<template>
    <div class="wrapper">
    </div>

</template>
  
<script>
export default {
    components: {
    },
    props: {},
    data() {
        return {
        };
    },
    watch: {},
    computed: {

    },
    methods: {
        getLocationGps(code) {
            let that = this
            upsdk.getLocationGps({
                success: function (res) {
                console.log(res)
                if (typeof res == 'string') {
                    res = JSON.parse(res)
                }
                if(process.env.NODE_ENV !== 'production'||process.env.NODE_ENV == 'test'){
                    that.unionPay(code)
                }else{
                    sessionStorage.setItem('latitude', res.latitude)
                    sessionStorage.setItem('longitude', res.longitude)
                    that.unionPay(code)

                }

                
                },
                fail: function (e) {
                console.log('报错', e)
                if (e.code == 1002) {
                    that.getLocationGps(code)
                } else {
                    that.$toast({
                    className: 'toastName',
                    getContainer: '.wrapper',
                    duration: '5000',
                    message: '抱歉，检测到无法获取手机定位信息，您暂时无法参与本次活动。\n定位功能自查小Tips:\n1.是否开启手机中的定位服务\n2.是否允许云闪付获取您手机的地理位置授权',

                    })
                }
                // that.canDo = true


                }
            });
        },
        //云闪付登录
        unionPay(code) {
            getLogin({
                code: decodeURIComponent(code),
                latitude: sessionStorage.getItem('latitude'),
                longitude: sessionStorage.getItem('longitude')
            }).then((res) => {
                var data = res.data.data;
                var token = data.tokenHead + " " + data.token;
                localStorage.setItem("token", token);
                localStorage.setItem("tokenHeader", data.tokenHeader);
                localStorage.setItem("userInfo", JSON.stringify(data));
                this.preload()
            }).catch(e=>{
                if(e.data.code == 506){
                    this.$refs.geographic.show = true
                    
                }else if(e.data.code == 508){
                    this.text = e.data.message
                    this.$refs.noValidation.type = 2
                    this.$refs.noValidation.show = true
                }
            });
        },
        sdkAuth() {
            let that = this
            sdkAuth({
                url: window.location.href.split("#")[0],
            }).then((res) => {
                var data = res.data.data;
                upsdk.config({
                    appId: data.appId, // 必填，接入方的唯一标识
                    timestamp: data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: data.nonceStr, // 必填，生成签名的随机串
                    signature: data.signature, // 必填，签名因子包括 appId、frontToken、nonceStr、timestamp、url
                    debug: false // 开发阶段可打开此标记，云闪付APP会将调试信息toast出来
                });
                upsdk.ready(() => {
                    upsdk.appletAuth({
                        success: (r) => {
                            that.getLocationGps(r.code)
                            //  data ={code:’xxx’}
                        }
                    })
                });

            })
        },
        preload() {
            let imgs = [
            ];
            for (let img of imgs) {
                let image = new Image();
                image.src = img;
                image.onload = () => {
                    this.count++;
                    // 计算图片加载的百分数，绑定到percent变量
                    let percentNum = Math.floor((this.count / imgs.length) * 100);
                    this.percent = Math.floor(percentNum) + "%";
                    if (percentNum == 100) {
                        setTimeout(()=>{
                            this.$router.replace({path:"/home"})
                        },1000)
                    }
                };
            }
        },
        // 获取连接参数正则
        getQueryString(name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
            var r = window.location.search.substr(1).match(reg);
            if (r != null) return unescape(r[2]);
            return null;
        },

    },
    created() {
        // require('@/assets/js/vconsole')
        if (process.env.NODE_ENV !== 'production' || process.env.NODE_ENV == 'test') {
            // sessionStorage.setItem('latitude','26.109128'),
            // sessionStorage.setItem('longitude','119.292291')//福州
            // sessionStorage.setItem('latitude','24.486719'),
            // sessionStorage.setItem('longitude','118.130026')//厦门
            this.sdkAuth()
            // this.unionPay('1')
        } else {
            this.sdkAuth()
        }


    },
    mounted() {
        // document.title = '爱泉州 大乐购'
        // upsdk.setNavigationBarTitle({
        //     title: '爱泉州 大乐购'
        // })

    },
};
</script>
<style scoped  lang="scss">
/* .connection {
    position: relative;
    width: 5.51rem;
    height: 1.04rem;
    background-image: url(../../assets/images/connection.png);
    background-size: 100% auto;
    background-position: bottom;
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-top: -1.03rem;
    z-index: 10;
} */
.wrapper>>>.toastName{
    width: 4.5rem !important;
    text-align: left !important;
}
.wrapper{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}
</style>